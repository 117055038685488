// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

h1, h2, h3, h4, h5, h6 {
  margin: 0
}

.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json>pre {
  background: none #fff !important;

}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.table-container {
  padding: 20px;
}

.gen-bkground {
  background: linear-gradient(125deg, rgba(4, 171, 147, 1) 25%, rgba(0, 121, 255, 1) 100%);
  color: #fff;
}

.aside-bkground {
  background: linear-gradient(125deg, rgba(17, 151, 192, 1) 5%, rgba(4, 7, 25, 1) 100%);
  color: #fff;
}

.btn-primary {
  background: linear-gradient(125deg, rgba(4, 171, 147, 1) 5%, rgba(0, 121, 255, 1) 100%);
  border: none;

  &:disabled {
    cursor: default !important;
  }
}

.fab {
  background: linear-gradient(125deg, rgba(4, 171, 147, 1) 25%, rgba(0, 121, 255, 1) 100%) !important;
  color: #fff !important;
}

.single-fab {
  position: absolute !important;
  right: 50px;
  bottom: 75px;
  z-index: 10;
}

.speed-dial {
  position: fixed;
  bottom: 75px;
  right: 35px;

  @media (max-width: 767px) {
    bottom: 90px;
  }
}

.circular-progress {
  position: absolute;
  right: 45vw;
  top: 35vh;
  z-index: 200;
}

.tab-selector {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    justify-content: start;
  }
}

.dialog-title {
  display: flex;
  justify-content: space-between;
}

.MuiDialogContent-root {
  max-height: 80vh !important;
}

.MuiToggleButton-root {
  color: #3783e7 !important;

}

.MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #3783e7 !important;
}

.react-emoji-picker--wrapper {
  position: fixed;
  bottom: unset;
  right: unset;
  margin-top: 55px;
}

// calendar styles
.calendar-selector {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -4.6em;

  @media (max-width: 599px) {
    position: absolute;
    top: 142px;
    right: 4vw;
  }
}

.fc-view-harness {
  background: #fff;
}

.fc-day-today {
  color: #2d8cf6;
  background: #f2f2f2 !important;
}

.fc-daygrid-day-events {
  color: black;
}

.fc-toolbar {
  display: none !important; // remove if have to back to original options of fullCalendar
}

.fc-toolbar-title {
  color: #000 !important;
  font-size: 2.75em !important;
}

.fc-toolbar-chunk {
  display: flex;
}

.fc-button {
  height: 37px;
  background: transparent !important;
  border: none !important;
  font-size: 1.1rem !important;
  color: #000 !important;

  font-family: 'Poppins', sans-serif !important;
  box-sizing: border-box !important;
  line-height: 1.75 !important;
  text-transform: uppercase !important;

  box-shadow: none !important;

  :hover {
    background-color: gray !important;
  }
}

.fc-customTodayBtn-button {
  padding: 0.4em 30px !important;
  background: white !important;
  
}

.fc-customPrevBtn-button,
.fc-customNextBtn-button {
  width: 37px;
  border-radius: 50% !important;
  font-weight: 500 !important;
}

.fc-event {
  cursor: pointer;
  border: solid 0.5px #a5a5a5;
  transition: border 0.5s ease 0s;
}

.fc-event-main {
  padding: 5px 0 0 5px !important;
}

.fc-header-toolbar {
  margin: 1.5em;

  @media (max-width: 599px) {
    margin-top: -0.5em;
  }
}

// END-CALENDAR-STYLES

.accordion {
  box-shadow: none !important;
  border: 0.5px solid rgb(193, 193, 193);
}

// dropzones styles start
.drop {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 2px;
  border: 2px dashed #eeeeee;
  background: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 10px;

  :hover {
    border: 2px dashed #5d78ff;
    cursor: pointer;
  }
}

.dz-list {
  list-style-type: none;
  color: #000;
  text-decoration: underline;

  :hover {
    color: #5d78ff;
    cursor: pointer;
  }
}

// dropzone styles END

// .nav-link:focus {  
//     outline: none;  
// }

.blured-box {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-family: sans-serif;
  line-height: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 20px 40px;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.blured-xs-box {
  background-color: #b2b2b25e;
  border-radius: 15px;
  font-family: sans-serif;
  line-height: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  width: 100%;
}

.grid-wrapper {
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.rotate-45 {
  transform: rotate(45deg)
}

.rotate-90 {
  transform: rotate(90deg)
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.apexcharts-tooltip {
  padding: 5px;
  font-size: smaller;
}

.tagsSelector {
  padding-bottom: 14px;
}

.react-tel-input {
  height: 54px;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 100% !important;
  border: none;
  background-color: $input-solid-bg;
	border-color: $input-solid-bg;
	@include placeholder($input-solid-placeholder-color);
	color: $input-solid-color;
	transition: $transition-input;
}

.react-tel-input .form-control:focus {
  background-color: $input-solid-bg-focus;
	border-color: $input-solid-bg-focus;
	color: $input-solid-color;
	transition: $transition-input;
}

.logo-carrousel-image {
  filter: drop-shadow(0px 0px 10px rgba(0, 0,0,0.8));
  height: 150px;
}

.carrousel-logos {
  margin-top: 15px;
}

.img-features {
  height: 500px;
}

.img-features.fs {
  height: 500px;
}

.case-studies-container {
  width: 80%;
  height: fit-content;
}

.img-case-studies-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.img-case-studies {
  height: 150px;
  border-radius: 6px !important;
  transition: all linear 0.15s;
  box-shadow: 10px 10px 10px #48529944;
}

.img-case-studies:hover {
  transform: scale(1.1);
}

.full-screen-carrousel-container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10000;
}


.full-screen-carrousel {
  z-index: 10001;
  width: 95%;
  height: 90%;
}

.img-features-fs {
  height: 100%;
  width: 90%;
}

@media (max-width: 1441px) {
  .img-features-fs {
    height: 100%;
  }
  .img-features {
    height: 300px;
  }
}

@media (max-width: 992px) {
  .full-screen-carrousel {
    height: 50%;
  }
  .img-features-fs {
    width: 100%;
  }
  .case-studies-container {
    width: 100%;
    position: relative;
  }
  .img-case-studies-container {
    gap: 20px;
  }
  .img-features {
    height: 175px;
  }
}

